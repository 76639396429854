import { useState, useEffect, useRef } from "react";
import { CallsClient } from "_graphql/clients/CallsClient";
import { Button, message } from "@centrate-io/barn";
import { getErrors } from "_helpers/api";
import {
  Select,
  TaskBadge,
  Tag,
  Flex,
  InputLabel,
  FormInput,
  Quill,
} from "_styleguide";
import { createEmail } from "_graphql/mutations/email";
import { IconX, IconCheck, IconSend } from "@tabler/icons-react";
import { useBar } from "_contexts/useBar";
import { useIntegrations } from "_contexts/useIntegrations";
import { fetchTemplates, replaceData } from "../../_helpers/templates";
import CompleteTask from "App/Admin/Application/_components/Tasks/_components/CompleteTask/CompleteTask";
import { useApolloClient } from "@apollo/client";
import Search from "../Search/Search";
import Missing from "../Missing/Missing";
import "./Email.scss";

function Email(props) {
  const callsClient = CallsClient;
  const client = useApolloClient();
  const { activeData, closeCall } = useBar();
  const { integrations } = useIntegrations();
  const [sending, setSending] = useState(false);
  const [form, setForm] = useState({
    email_to: activeData?.customer?.email,
    email_from: "matt.angelini@paypath.ai",
    subject: activeData?.task?.subject || undefined,
    body: "",
    is_html: false,
  });
  const [data, setData] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [template, setTemplate] = useState(
    activeData?.task?.template_id || undefined,
  );
  const [completeModal, setCompleteModal] = useState(false);
  const iframeRef = useRef(null);
  const emailAddress = integrations?.email?.settings?.email;

  const minimize = activeData?.minimize;
  const APPLICATION_ID = activeData?.customer?.id;
  const TASK_ID = activeData?.task?.id;

  const sendEmail = async () => {
    setSending(true);
    let emailBody = form.body;
    if (form.is_html) {
      const iframeDoc =
        iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow.document;
      emailBody = iframeDoc.documentElement.outerHTML;
    }
    try {
      await callsClient.mutate({
        variables: {
          application_id: APPLICATION_ID,
          task_id: TASK_ID || null,
          email_to: form.email_to,
          email_from: emailAddress,
          subject: form.subject,
          body: emailBody?.toString(),
          is_html: form.is_html,
        },
        mutation: createEmail,
      });
      message.success("Email sent");
      setTemplate(null);
      setSending(false);
      if (activeData?.task?.id) {
        setCompleted(true);
      } else {
        closeCall();
      }
    } catch (err) {
      message.error(getErrors(err) || "Error sending message, try again");
      setSending(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchTemplates(
          client,
          "email",
          activeData.customer?.id,
        );
        setData(data); // Handle the fetched data
        if (activeData?.task?.template_id) {
          const templateData = data?.templates?.find(
            (t) => t.id === activeData?.task?.template_id,
          );
          if (!templateData?.instructions) {
            setTemplate(undefined);
            return;
          }
          const newInstructions = replaceData(
            templateData,
            data.customer,
            props.user,
          );
          updateMany(
            templateData?.subject,
            newInstructions,
            templateData.is_html,
          );
        }
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };
    if (activeData.customer?.id) fetchData();
  }, []);

  const changeTemplate = (templateId) => {
    const templateData = data?.templates?.find((t) => t.id === templateId);
    setTemplate(templateId);
    if (!templateData) {
      updateMany(null, null);
      return;
    }
    const newInstructions = replaceData(
      templateData,
      data.customer,
      props.user,
    );
    updateMany(templateData?.subject, newInstructions, templateData.is_html);
  };

  const templateOptions = data?.templates || [];

  // Update form by field and value
  const updateForm = (field, value) => {
    const editedform = { ...form };
    editedform[field] = value;
    setForm(editedform);
  };
  const updateMany = (subject, body, is_html) => {
    const editedform = { ...form };
    editedform.subject = subject || "";
    editedform.body = body || "";
    editedform.is_html = is_html ? true : false;
    if (is_html) setIframe(body || "");
    setForm(editedform);
  };

  const setIframe = (content) => {
    if (!iframeRef?.current) return;
    const iframeDoc =
      iframeRef.current.contentDocument ||
      iframeRef.current.contentWindow.document;
    iframeDoc.open();
    iframeDoc.write(content);
    iframeDoc.close();
    iframeDoc.designMode = "on";
  };

  const reload = () => {
    props.fetchTaskCount();
    closeCall();
  };

  if (!integrations?.email) {
    return <Missing type="email" />;
  }

  return !activeData?.customer ? (
    <Search type="email" />
  ) : (
    <div className={`pp-email ${minimize ? "minimize" : ""}`}>
      {completeModal ? (
        <CompleteTask
          taskId={activeData?.task?.id}
          reload={reload}
          removeModal={() => setCompleteModal(false)}
        />
      ) : null}
      <div className="texting-info">
        {activeData?.customer ? (
          <h3>
            {activeData?.customer?.first_name} {activeData?.customer?.last_name}
          </h3>
        ) : null}
        {activeData?.task ? (
          <Tag size="small" type="task">
            {activeData?.task?.title}
          </Tag>
        ) : null}
      </div>
      <div className={`pp-templates ${props.visible ? "visible" : ""}`}>
        <Select
          value={template}
          onChange={changeTemplate}
          size="medium"
          disabled={sending || completed}
          popupClassName="pp-select-dropdown ppsd-task"
          placeholder="---"
        >
          <Select.Option value={null}>---</Select.Option>
          {templateOptions.map((t) => {
            return (
              <Select.Option key={t.id} value={t.id}>
                <TaskBadge type="email" />
                {t.title}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div className="email-window">
        <Flex className="email-tofrom">
          <Flex vertical={true}>
            <InputLabel htmlFor="email_to">To *</InputLabel>
            <FormInput
              id="email_to"
              value={form.email_to}
              disabled={true}
              // onChange={(e) => updateForm("email_to", e.target.value)}
              placeholder="e.g. customer@email.com"
            />
          </Flex>
          <Flex vertical={true}>
            <InputLabel htmlFor="email_from">From *</InputLabel>
            <FormInput
              id="email_from"
              value={emailAddress}
              disabled={true}
              // onChange={(e) => updateForm("email_from", e.target.value)}
              placeholder="e.g. john@email.com"
            />
          </Flex>
        </Flex>
        <Flex vertical={true}>
          <InputLabel htmlFor="subject">Subject *</InputLabel>
          <FormInput
            id="subject"
            value={form.subject}
            disabled={sending || completed}
            onChange={(e) => updateForm("subject", e.target.value)}
            placeholder="e.g. Welcome to PayPath..."
          />
        </Flex>
        <Flex vertical={true}>
          <InputLabel htmlFor="subject">Body *</InputLabel>

          <iframe
            title="email-editor"
            ref={iframeRef}
            style={{
              width: "100%",
              height: "300px",
              border: "1px dotted #cfcfcf",
              borderRadius: "6px",
              display: form.is_html ? "block" : "none",
              pointerEvents: completed ? "none" : "auto",
            }}
          />
          {!form.is_html ? (
            <Quill
              value={form.body}
              onChange={(val) => updateForm("body", val)}
              readOnly={sending || completed}
            />
          ) : null}
        </Flex>
      </div>

      <div className="email-actions">
        {completed ? (
          <Button
            className="green-btn boxed"
            type="primary"
            onClick={() => setCompleteModal(true)}
          >
            <IconCheck /> Complete Task
          </Button>
        ) : (
          <Button
            className="boxed"
            type="primary"
            loading={sending}
            onClick={() => sendEmail()}
          >
            <IconSend /> Send Email
          </Button>
        )}
      </div>
      <div className="dialer-close" onClick={closeCall}>
        <IconX />
      </div>
    </div>
  );
}

export default Email;
