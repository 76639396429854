import "./BarIcon.scss";

function BarIcon(props) {
  const isActive = props.data?.type === props.name;
  const disabled =
    props.type === "outreach" &&
    props.data?.type &&
    props.data?.type !== props.name;
  return (
    <div
      onClick={disabled ? null : props.onClick || props.actions[props.name]}
      className={`bar-icon ${isActive ? "active" : ""}  ${disabled ? "disabled" : ""}`}
    >
      {props.icon}
    </div>
  );
}

export default BarIcon;
