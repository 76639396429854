import React, { useState, useEffect } from "react";
import { useBar } from "_contexts/useBar";
import { useIntegrations } from "_contexts/useIntegrations";
import {
  IconGridDots,
  IconNotes,
  IconMicrophone,
  IconMicrophoneOff,
  IconPhoneCall,
  IconX,
  IconCheck,
} from "@tabler/icons-react";
import { Tag } from "_styleguide";
import { getToken } from "./_helpers/twilio";
import { Device } from "@twilio/voice-sdk";
import io from "socket.io-client";
import DialPad from "./_components/DialPad/DialPad";
import Script from "./_components/Script/Script";
import CompleteTask from "App/Admin/Application/_components/Tasks/_components/CompleteTask/CompleteTask";
import { Button } from "@centrate-io/barn";
import Search from "../Search/Search";
import Missing from "../Missing/Missing";
import "./Dialer.scss";

function Dialer(props) {
  const { activeData, closeCall } = useBar();
  const { integrations } = useIntegrations();
  const [device, setDevice] = useState(null);
  const [call, setCall] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [showDialPad, setShowDialPad] = useState(false);
  const [showScript, setShowScript] = useState(activeData?.task?.template_id);
  const [isCalling, setIsCalling] = useState(false);
  const [callStatus, setCallStatus] = useState("ready");
  const [socket, setSocket] = useState(null);
  const [callTime, setCallTime] = useState(0);
  const [timerId, setTimerId] = useState(null);
  const [completeModal, setCompleteModal] = useState(false);
  const finalStatuses = [
    "canceled",
    "completed",
    "failed",
    "no-answer",
    "busy",
  ];
  const isCompleted = finalStatuses.includes(callStatus);
  const isDisabled = isCompleted || !device || !socket;

  const initializeDevice = async () => {
    const token = await getToken();
    const newDevice = new Device(token, {
      codecPreferences: ["opus", "pcmu"],
      enableRingingState: true,
    });
    setDevice(newDevice);

    // Disable dumb sounds
    newDevice.audio.outgoing(false);
    newDevice.audio.disconnect(false);

    newDevice.on("error", (error) => {
      console.log("Error with Twilio device:", error);
      handleDisconnect();
    });
  };

  const makeCall = async () => {
    if (isDisabled) return;
    if (device) {
      const params = {
        customer_phone: activeData?.customer?.phone,
        user_phone: integrations?.call?.settings?.phone,
        fromDialer: "paypath",
        mode: activeData?.mode,
        user_id: props.user?.id,
        company_id: props.user?.company?.id,
        socketId: socket,
      };
      if (activeData?.customer?.id) {
        params.application_id = activeData?.customer?.id;
      }
      if (activeData?.task?.id) {
        params.task_id = activeData?.task?.id;
      }
      const activeCall = await device.connect({ params });

      activeCall.on("error", (error) => {
        handleDisconnect();
      });
      activeCall.on("disconnect", () => {
        handleDisconnect();
      });
      setIsCalling(true);
      setCall(activeCall);
    }
  };

  const hangUp = () => {
    if (isDisabled) return;
    if (device) {
      device.disconnectAll();
      setIsCalling(false);
    }
  };

  const startTimer = () => {
    setCallTime(0); // Reset the call time
    const id = setInterval(() => {
      setCallTime((prevTime) => prevTime + 1); // Increment call time every second
    }, 1000);
    setTimerId(id); // Store the interval ID to clear later
  };

  // Function to stop the timer
  const stopTimer = () => {
    if (timerId) {
      clearInterval(timerId); // Clear the interval
      setTimerId(null); // Reset the timer ID
    }
  };

  const handleDisconnect = () => {
    setIsCalling(false);
    setCall(null);
    setIsMuted(false);
    setShowScript(false);
    setShowDialPad(false);
    stopTimer();
    if (device) {
      device.disconnectAll();
    }
  };

  const toggleMute = () => {
    if (call) {
      if (isMuted) {
        call.mute(false);
        setIsMuted(false);
      } else {
        call.mute(true);
        setIsMuted(true);
      }
    }
  };

  const toggleDialPad = () => {
    if (!isCalling) return;
    setShowDialPad(showDialPad ? false : true);
    setShowScript(false);
  };

  const toggleScript = () => {
    setShowScript(showScript ? false : true);
    setShowDialPad(false);
  };

  useEffect(() => {
    return () => {
      stopTimer();
    };
  }, [timerId]);

  useEffect(() => {
    initializeDevice();

    // do stuff here

    return () => {
      if (device) {
        device.disconnectAll();
      }
    };
  }, []);

  useEffect(() => {
    // Initialize the socket inside the component
    const newSocket = io(process.env.REACT_APP_CALLS_API_PATH, {
      withCredentials: true,
    }); // Replace with your server's URL

    newSocket.on("connect", () => {
      setSocket(newSocket.id);
    });

    // Listen for call status updates from the server
    newSocket.on("callStatus", async (data) => {
      setCallStatus(data.status);
      if (data.status === "in-progress") {
        startTimer();
      }
    });

    // Clean up the socket connection when the component unmounts
    return () => {
      newSocket.off("connect");
      newSocket.off("callStatus");
      newSocket.disconnect();
    };
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  const reload = () => {
    props.fetchTaskCount();
    closeCall();
  };

  const minimize = activeData?.minimize;

  if (!integrations?.call) {
    return <Missing type="call" />;
  }

  console.log("integrations", integrations);

  return !activeData?.customer ? (
    <Search type="call" />
  ) : (
    <div
      className={`pp-dialer ${showScript ? "script" : ""} ${minimize ? "minimize" : ""}`}
    >
      {completeModal ? (
        <CompleteTask
          taskId={activeData?.task?.id}
          reload={reload}
          removeModal={() => setCompleteModal(false)}
        />
      ) : null}
      {callStatus === "ready" || isCompleted ? (
        <div className="dialer-close" onClick={closeCall}>
          <IconX />
        </div>
      ) : null}
      <div className="dialer-info">
        <div className="dialer-title">
          {activeData?.customer ? (
            <h3>
              {activeData?.customer?.first_name}{" "}
              {activeData?.customer?.last_name}
            </h3>
          ) : null}
          {activeData?.task ? (
            <Tag size="small" type="task">
              {activeData?.task?.title}
            </Tag>
          ) : null}
        </div>
        <div className="timer">
          {activeData?.customer?.phone}&nbsp;&nbsp;&nbsp;
          <span className={callStatus} />
          {callStatus === "ready" && !isCalling ? "Ready" : ""}
          {isCompleted && !isCalling ? "Completed" : ""}
          {isCalling && callTime > 0
            ? formatTime(callTime)
            : isCalling
              ? "Calling..."
              : ""}
        </div>
      </div>
      {isCalling && showDialPad && !isDisabled ? <DialPad call={call} /> : null}
      <Script visible={showScript && !isDisabled} {...props} />
      {isCompleted && activeData?.task?.id ? (
        <div className="dialer-finished">
          <Button
            className="green-btn boxed"
            type="primary"
            size="small"
            onClick={() => setCompleteModal(true)}
          >
            <IconCheck /> Complete Task
          </Button>
        </div>
      ) : (
        <div className="dialer-menu">
          <div
            className={`dialer-icon ${isDisabled ? "disabled" : ""} ${showScript && !isDisabled ? "active" : ""}`}
            onClick={!isDisabled ? toggleScript : null}
          >
            <IconNotes />
          </div>
          <div
            className={`dialer-icon ${!isCalling || isDisabled ? "disabled" : ""} ${isCalling && showDialPad && !isDisabled ? "active" : ""}`}
            onClick={!isDisabled ? toggleDialPad : null}
          >
            <IconGridDots />
          </div>
          <div
            className={`dialer-icon ${!call || isDisabled ? "disabled" : ""}`}
            onClick={!isDisabled ? toggleMute : null}
          >
            {isMuted ? <IconMicrophoneOff /> : <IconMicrophone />}
          </div>
          <div
            className={`dialer-icon call-btn ${isCalling ? "in-progress" : "ready"} ${isDisabled ? "disabled" : ""}`}
            onClick={isCalling ? hangUp : makeCall}
          >
            <IconPhoneCall />
          </div>
        </div>
      )}
    </div>
  );
}

export default Dialer;
