import React from "react";
import { IconMail } from "@tabler/icons-react";
import moment from "moment";

export default function Status(props) {
  const { activity, customer } = props;
  const user = activity?.user;

  const status = activity.email;
  let icon = <IconMail />;

  return (
    <div className={"app-activity status-"}>
      <div className="act-icon">{icon}</div>
      <div className="descript">
        {!status.incoming && (
          <React.Fragment>
            <b>
              {user.name} &lt;{status.from_email}&gt;
            </b>{" "}
            emailed{" "}
            <b>
              {customer?.first_name + " " + customer?.last_name} &lt;
              {status.to_email}&gt;
            </b>{" "}
            with the subject <b>{status.subject}</b>
          </React.Fragment>
        )}
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
          {moment(activity.createdAt * 1).format("MMM D @ h:mm a")}
        </div>
        &nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;
      </div>
    </div>
  );
}

//
