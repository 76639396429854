import React, { useState } from "react";
import TwilioSVG from "_assets/logos/twilio.svg";
import { IconPlugConnected } from "@tabler/icons-react";
import TwilioPhoneModal from "../_modals/TwilioPhone/TwilioPhone";
import { Button } from "@centrate-io/barn";

const TwilioConnect = (props) => {
  const [modal, setModal] = useState(false);
  const handleLogin = async () => {
    window.location.href = `${process.env.REACT_APP_API_PATH}/login/microsoft?user_id=${props.user?.id}&company_id=${props.user?.company?.id}`;
  };

  return (
    <>
      {modal ? (
        <TwilioPhoneModal
          reload={props.reload}
          user={props.user}
          removeModal={() => setModal(false)}
        />
      ) : null}
      <Button className="boxed" type="primary" onClick={() => setModal(true)}>
        <IconPlugConnected /> Setup Twilio
      </Button>
    </>
  );
};

const TwilioPhone = {
  type: "twilio_phone",
  category: "call",
  logo: TwilioSVG,
  name: "Twilio Voice",
  description:
    "Select an area code and phone number to enable calling customers and completing call tasks in PayPath.",
  Connect: TwilioConnect,
};

export default TwilioPhone;
