import { Link } from "react-router-dom";
import { IconPuzzle, IconX } from "@tabler/icons-react";
import { Button } from "@centrate-io/barn";
import { useBar } from "_contexts/useBar";
import "./Missing.scss";

const INT_TYPES = {
  email: "Emailing",
  call: "Calling",
  text: "Texting",
};

function Missing(props) {
  const { closeCall } = useBar();
  return (
    <div className="bar-missing">
      <h4>
        No active integration for <b>{INT_TYPES[props.type]}</b>
      </h4>
      <Link to="/settings/integrations" onClick={closeCall}>
        <Button className="boxed" type="primary">
          <IconPuzzle /> Manage Integrations
        </Button>
      </Link>
      <div className="dialer-close" onClick={closeCall}>
        <IconX />
      </div>
    </div>
  );
}

export default Missing;
